import * as React from "react";
import * as ReactDOM from "react-dom";
import HouseLoan from "./calculators/house-loan/house-loan";
import CarLoan from "./calculators/car-loan/car-loan";
import ConsumerLoan from "./calculators/consumer-loans/consumer-loan";

const CONFIG_SELECTOR = "[betri-react]";

const applications: { name: string; component: React.ComponentType<any> }[] = [
    {
        name: "house-loan",
        component: HouseLoan
    },
    {
        name: "car-loan",
        component: CarLoan
    },
    {
        name: "consumer-loan",
        component: ConsumerLoan
    }
];

function mountCalculators() {
    const modules = document.querySelectorAll(CONFIG_SELECTOR);

    modules.forEach(scriptNode => {
        const data = JSON.parse(scriptNode.innerHTML);
        try{
            const componentName = scriptNode.attributes.getNamedItem("betri-react")?.value;
            
            const Component = applications.find(app => {
                return app.name === componentName;
            })?.component as React.ComponentType<any>;
            

            const node: HTMLElement = global.window.document.createElement("div");
            node.classList.add("betri-module");
            scriptNode.after(node);

            ReactDOM.render(
                <React.StrictMode>
                    <Component config={data.config} />
                </React.StrictMode>,
                node
            );
        }catch(e){
             /* tslint:disable */
             console.log("Didn't find component");
             /* tslint:enable */
        }
    });
}

export default mountCalculators;


