import * as React from "react";
import Currency from "../../../ui/currency/currency";
import { LoanSummaryData } from "../../../math-utils/loan-math";
import BetriButton from "../../../ui/button/button";
import Summary from "../../../ui/summary/summary";
import SummaryRow from "../../../ui/summary/summary-row";

interface LoanSummaryProps {
    topText: string;
    buttonText: string;
    summaryText: string;
    loanSummaryData: LoanSummaryData[];
    monthlyTotal: number;
    onApplyClick: () => void;
}

const LoanSummary: React.FC<LoanSummaryProps> = props => {
    const { topText, buttonText, summaryText, monthlyTotal, loanSummaryData, onApplyClick } = props;
    return (
        <div className="betri-loan-output">
            <div className="betri-loan-result-top">
                <div>{topText}</div>
                <div className="betri-loan-result-top-amount">
                    <Currency amount={Math.round(monthlyTotal)} suffix=',-' />
                </div>
            </div>
            <div className="betri-loan-cta">
                <BetriButton
                    onClick={() => {
                        onApplyClick();
                    }}
                >
                    {buttonText}
                </BetriButton>
            </div>
            <Summary className="betri-loan-summary">
                <b>{summaryText}</b>
                {loanSummaryData.map((rowItem, idx: number) => {
                    return (
                        <SummaryRow
                            key={idx}
                            left={rowItem.title}
                            right={rowItem.value}
                            tooltip={rowItem.info}
                        />
                    );
                })}
            </Summary>
        </div>
    );
};

export default LoanSummary;
