import * as React from "react";
import { SUBMIT_BUTTON_CSS_CLASS, TEXT_AREA_CSS_CLASS } from "../../constants/default-config";
import { ConsumerLoanLabels, SharedLabels } from "../../constants/translations";
import useFormIntegration from "../../hooks/use-form-integration";
import { urlLoanValue, urlTermValue } from "../../libs/url-params";
import { LoanSummaryData } from "../../math-utils/loan-math";
import { ConsumerLoanCalculationResult, createConsumerLoanSummaryData, getConsumerLoanCalculations } from "../../math-utils/loan-math-consumer";
import BetriCard from "../../ui/card/betri-card";
import Currency from "../../ui/currency/currency";
import FooterText from "../../ui/footer-text/footer-text";
import BetriForm from "../../ui/form/betri-form";
import GridColumn from "../../ui/grid/grid-column";
import GridContainer from "../../ui/grid/grid-container";
import SlideAndInput from "../../ui/slide-and-input/slide-and-input";
import { SliderConfig } from "../../ui/slider/slider";
import CalculatorTitle from "../../ui/title/calculator-title";
import Year from "../../ui/year/year";
import LoanSummary from "../shared/loan-summary/loan-summary";

export interface ConsumerLoanConfig {
    loan: SliderConfig;
    term: SliderConfig;
    rent: number;
    rentSupport: number;
    maxRentSupport: number;
}
export interface ConsumerLoanProps {
    config: ConsumerLoanConfig;
    scForm?: {
        textareaSelector?: string;
        submitButtonSelector?: string;
        formToHideSelector?: string;
    };
}

const ConsumerLoan: React.FC<ConsumerLoanProps> = ({ config }) => {
    const { submitData } = useFormIntegration(TEXT_AREA_CSS_CLASS, SUBMIT_BUTTON_CSS_CLASS);
    const refForScroll = React.useRef<HTMLDivElement>(null);
    const [formVisible, setFormVisible] = React.useState<boolean>(false);

    const [loanValue, setLoanValue] = React.useState<number>(urlLoanValue || config.loan.value);
    const [termValue, setTermValue] = React.useState<number>(urlTermValue ||config.term.value);


    const calculated: ConsumerLoanCalculationResult = React.useMemo(
        () => {
            return getConsumerLoanCalculations(
                config,
                loanValue,
                termValue,
            );
        },
        [config, loanValue, termValue]
    );

    const onChangeLoan = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = +e.target.value;
        if (value <= config.loan.max) {
            setLoanValue(value);
        }
    };

    const onApply = () => {
        setFormVisible(true);
        if (refForScroll.current?.offsetTop) {
            window.scrollTo({ top: refForScroll.current?.offsetTop - 150, behavior: "smooth" })
        }
    }
    
    // // for use in both display and for sending in form
    const summaryData:LoanSummaryData[] = createConsumerLoanSummaryData({
        calculatedResult: calculated
    })

    return (
        <GridContainer>
        <CalculatorTitle title={ConsumerLoanLabels.title} />
            <GridColumn>
                <BetriCard>
                    <div>
                        <SlideAndInput
                            title={ConsumerLoanLabels.loan}
                            loan={config.loan}
                            value={loanValue}
                            setValue={setLoanValue}
                            onChangeInput={onChangeLoan}
                            formattingFunction={v => <Currency amount={v} />}
                        />
                        <SlideAndInput
                            title={ConsumerLoanLabels.term}
                            loan={config.term}
                            value={termValue}
                            setValue={setTermValue}
                            formattingFunction={v => <Year amount={v} />}
                        />
                    </div>
                </BetriCard>
            </GridColumn>
            <GridColumn>
                <BetriCard>
                    <LoanSummary monthlyTotal={calculated.totalWithSupport} loanSummaryData={summaryData} onApplyClick={onApply} topText={SharedLabels.resultTop} buttonText={ConsumerLoanLabels.buttonText} summaryText={SharedLabels.summaryTopText}/>
                </BetriCard>
            </GridColumn>

            <div ref={refForScroll} />
            {formVisible && (
                <GridColumn>
                    <BetriCard>
                        <BetriForm summaryData={summaryData} submitData={submitData} />
                    </BetriCard>
                </GridColumn>
            )}
            <GridColumn>
                <FooterText boldText={SharedLabels.cautionSpan} text={SharedLabels.caution}  />
            </GridColumn>
        </GridContainer>
    );
};

export default ConsumerLoan;
