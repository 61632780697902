import * as React from "react";

interface Props {
    className?: string;
}

// modified it, added a type, changed children to props in order to add a classname.
const BetriCard: React.FC<Props> = props => {
    return <div className={`betri-card ${props.className}`}>{props.children}</div>;
};

export default BetriCard;
