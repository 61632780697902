import { BetriFormData } from "../ui/form/betri-form";

export interface SummaryData {
    title: string;
    value: string;
}

export const createFormSummaryData = (formData?: BetriFormData): SummaryData[] => {
    if (!formData) {
        return [];
    }

    const { name, address, email, phoneNumber, chooseCity } = formData;

    const loanFormData = [
        {
            title: "Name",
            value: name
        },
        {
            title: "Address",
            value: address
        },
        {
            title: "Email",
            value: email
        },
        {
            title: "Phone Number",
            value: phoneNumber
        }
    ];

    if (chooseCity) {
        loanFormData.push({
            title: "Chosen City",
            value: chooseCity
        });
    }

    return loanFormData;
};

export const formatFormSummaryDataForMailString = (data: SummaryData[]): string => {
    let text = "\n";
    text = text + "---------------------------\n";
    data.forEach(row => {
        text = text + row.title + ": " + row.value + "\n";
    });
    text = text + "---------------------------\n";
    return text;
};
