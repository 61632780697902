import * as React from "react";

interface BetriButtonProps {
    className?: string;
    isLoading?: boolean;
}

const BetriButton: React.FunctionComponent<
    React.ButtonHTMLAttributes<unknown> & BetriButtonProps
> = props => {
    return (
        <button type="button" {...props} className={`btn btn--default btn--primary ${props.className}`}>
            {props.children}
            {props.isLoading && <span className="betri-btn-loading-indication" />}
        </button>
    );
};

export default BetriButton;
