export enum LoanTypes {
    REGULAR = "Bústaðarlán",
    GREEN = "Grønt bústaðarlán"
}

export interface SharedLoanProps {
    scForm?: {
        textareaSelector?: string;
        submitButtonSelector?: string;
        formToHideSelector?: string;
    };
}
