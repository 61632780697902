import Slider, { SliderProps } from "rc-slider";
import * as React from "react";

export interface SliderConfig {
    min: number;
    max: number;
    step: number;
    value: number;
    formattingFunction?: (value: number) => React.ReactNode;
}
const customHandle = (config: SliderConfig): SliderProps["handle"] => {
    return props => {
        const {
            offset,
            reverse,
            dragging,
            vertical,
            prefixCls,
            ariaLabel,
            ariaLabelledBy,
            ariaValueTextFormatter,
            style,
            ref,
            ...rest
        } = props;
        const textOffset = props.offset > 50 ? 50 - props.offset + 12 : 50 - props.offset - 12;
        return (
            <div
                ref={ref}
                aria-valuemin={config.min}
                aria-valuemax={config.max}
                aria-valuenow={config.value}
                aria-disabled="false"
                role="slider"
                {...{ ...rest, tabindex: 0 }} // annoying workaround to make tabindex work correctly
                style={{
                    left: props.offset + "%",
                    transform: "translateX(-50%)"
                }}
            >
                <div className="betri-slider-tooltip">
                    <div
                        className="betri-slider-tooltip-text"
                        style={{
                            transform: "translateX(" + textOffset + "%)"
                        }}
                    >
                        {config.formattingFunction && config.formattingFunction(config.value)}
                        {!config.formattingFunction && <>{config.value}</>}
                    </div>
                </div>
            </div>
        );
    };
};

const BetriSlider: React.FC<
    SliderConfig & { value: number; onChange: (n: number) => void; hideRight?: boolean }
> = config => {
    return (
        <div className="betri-slider">
            <Slider
                min={config.min}
                max={config.max}
                value={config.value}
                onChange={config.onChange}
                step={config.step}
                handle={customHandle(config)}
            />

            <div className="betri-calc-row" style={{ marginTop: "8px" }}>
                {config.formattingFunction && config.formattingFunction(config.min)}
                {!config.formattingFunction && <div>{config.min}</div>}

                <div className={`${config.hideRight ? "hide-max-value" : ""}`}>
                    {config.formattingFunction && config.formattingFunction(config.max)}
                    {!config.formattingFunction && <div>{config.max}</div>}
                </div>
            </div>
        </div>
    );
};

export default BetriSlider;
