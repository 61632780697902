import React from "react";
import { SharedLabels } from "../../constants/translations";
import { createFormSummaryData, formatFormSummaryDataForMailString } from "../../math-utils/form-data";
import { LoanSummaryData } from "../../math-utils/loan-math";
import { formatLoanSummaryDataForMailString } from "../../math-utils/loan-math-house";
import BetriButton from "../button/button";
import FormTermsCheckbox from "../form-terms/form-terms";
import BetriLabelInput from "../label-and-input/label-and-input";
import { validateForm } from "./betri-form-validation";

export interface BetriFormData {
    name: string;
    address: string;
    email: string;
    phoneNumber: string;
    chooseCity?: string;
}

interface BetriFormProps {
    summaryData: LoanSummaryData[];
    submitData: (data: string) => void;
    hasChooseCityInput?: boolean;
}

const BetriForm: React.FunctionComponent<BetriFormProps> = props => {
    const { summaryData, submitData, hasChooseCityInput } = props;

    const [name, setName] = React.useState<string>("");
    const [address, setAddress] = React.useState<string>("");
    const [email, setEmail] = React.useState<string>("");
    const [phoneNumber, setPhoneNumber] = React.useState<string>("");
    const [chooseCity, setChooseCity] = React.useState<string>("");
    const [errorsObject, setErrorsObject] = React.useState<any>({});
    const [checkBoxChecked, setCheckBoxChecked] = React.useState<boolean>(false);
    const [disabled, setDisabled] = React.useState<boolean>(true);
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const onChangeTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCheckBoxChecked(e.target.checked);
        !checkBoxChecked ? setDisabled(e.target.value === "") : setDisabled(true);
    };

    const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const onChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(e.target.value);
    };

    const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const onChangePhoneNum = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(e.target.value);
    };

    const onChangeChooseCity = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChooseCity(e.target.value);
    };

    const onTrigger = () => {
        const errors = validateForm({ name, email, phoneNumber, address, chooseCity }, hasChooseCityInput);
        setErrorsObject(errors);

        let errorStatus = false;
        let key: keyof typeof errors;
        for (key in errors) {
            if (errors[key] !== undefined) {
                errorStatus = true;
            }
        }

        if (!errorStatus) {
            submitForm({ name, email, phoneNumber, address, chooseCity });
        }
    };

    const submitForm = (formData: BetriFormData) => {
        setSubmitting(true);

        const formSummaryData = createFormSummaryData(formData);

        const allData = formatLoanSummaryDataForMailString(summaryData).concat(
            formatFormSummaryDataForMailString(formSummaryData)
        );

        submitData(allData);
    };

    return (
        <div className="betri-calculator-form">
            <form action="" autoComplete="off">
                <div className="betri-formin-wrapper">
                    <BetriLabelInput
                        label={SharedLabels.formName}
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        onChange={onChangeName}
                        errorMessage={errorsObject.name}
                    />

                    <BetriLabelInput
                        label={SharedLabels.formAddress}
                        type="text"
                        id="address"
                        name="address"
                        value={address}
                        onChange={onChangeAddress}
                        errorMessage={errorsObject.address}
                    />

                    <BetriLabelInput
                        label={SharedLabels.formEmail}
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={onChangeEmail}
                        errorMessage={errorsObject.email}
                    />

                    <BetriLabelInput
                        label={SharedLabels.formTel}
                        type="text"
                        id="phone-number"
                        name="phone-number"
                        value={phoneNumber}
                        onChange={onChangePhoneNum}
                        errorMessage={errorsObject.phoneNumber}
                    />

                    {hasChooseCityInput && (
                        <BetriLabelInput
                            label={SharedLabels.formChooseCity}
                            type="text"
                            id="choose-city"
                            name="choose-city"
                            value={chooseCity}
                            onChange={onChangeChooseCity}
                            errorMessage={errorsObject.chooseCity}
                        />
                    )}
                </div>

                <FormTermsCheckbox
                    checked={checkBoxChecked}
                    onChange={onChangeTerms}
                    name="term"
                    id="term"
                    termsText={SharedLabels.terms}
                />
                <div className="betri-form-btn">
                    <BetriButton
                        type="button"
                        className="btn btn--default btn--primary"
                        value="Send"
                        name="submit"
                        id="submit_button"
                        disabled={disabled}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault();
                            onTrigger();
                        }}
                        isLoading={submitting}
                    >
                        {submitting ? <span>{SharedLabels.sending}</span> : <span>{SharedLabels.send}</span>}
                    </BetriButton>
                </div>
            </form>
        </div>
    );
};

export default BetriForm;
