import * as React from "react";
import { SUBMIT_BUTTON_CSS_CLASS, TEXT_AREA_CSS_CLASS } from "../../constants/default-config";
import { CarLoanLabels, SharedLabels } from "../../constants/translations";
import useFormIntegration from "../../hooks/use-form-integration";
import { urlLoanValue, urlTermValue } from "../../libs/url-params";
import { LoanSummaryData } from "../../math-utils/loan-math";
import { CarLoanCalculationResult, createCarLoanSummaryData, getCarLoanCalculations } from "../../math-utils/loan-math-car";
import BetriCard from "../../ui/card/betri-card";
import Currency from "../../ui/currency/currency";
import FooterText from "../../ui/footer-text/footer-text";
import BetriForm from "../../ui/form/betri-form";
import GridColumn from "../../ui/grid/grid-column";
import GridContainer from "../../ui/grid/grid-container";
import BetriRadio from "../../ui/radio/radio";
import SlideAndInput from "../../ui/slide-and-input/slide-and-input";
import { SliderConfig } from "../../ui/slider/slider";
import CalculatorTitle from "../../ui/title/calculator-title";
import Year from "../../ui/year/year";
import LoanSummary from "../shared/loan-summary/loan-summary";
import { SharedLoanProps } from "../shared/shared-configs";

export enum CarLoanTypes {
    GREEN = "Grønt Billán",
    REGULAR = "Billán"
}
// new enum and new carType in the interface for the car types
export enum CarTypes {
    NEW = "Nýggjur bilur",
    USED = "Brúktur bilur"
}

export interface CarLoanConfig {
    loan: SliderConfig;
    ownFinancing: SliderConfig;
    term: SliderConfig;
    text: {
        payment: string;
        loan: string;
        term: string;
        caution: string;
    };
    rent1: number;
    rent2: number;
    rentSupport: number;
    maxRentSupport: number;
    greenloan: { 
        rent1: number;
        rent2:number;
        maxLoan:number; // 3e5
    };
    loanType: CarLoanTypes;
    carType: CarTypes;
    rent: number;
    paymentPercent: number;
    creationPrice: number;
    handlingFee: number; // 3e5
    mortgageRegistration: number; // 1e3,
    mortgageRegistration2: number; // 2e3,
    

}
export interface CarLoanProps extends SharedLoanProps{
    config: CarLoanConfig;
    
}

const CarLoan: React.FC<CarLoanProps> = ({ config }) => {
    const { submitData } = useFormIntegration(TEXT_AREA_CSS_CLASS, SUBMIT_BUTTON_CSS_CLASS);
    const refForScroll = React.useRef<HTMLDivElement>(null);
    const [formVisible, setFormVisible] = React.useState<boolean>(false);

    const [loanValue, setLoanValue] = React.useState<number>(urlLoanValue ||config.loan.value);
    const [termValue, setTermValue] = React.useState<number>(urlTermValue ||config.term.value);
    const [loanType, setLoanType] = React.useState<CarLoanTypes>(CarLoanTypes.GREEN);
    const [carType, setCarType] = React.useState<CarTypes>(CarTypes.NEW);
   

    const calculated: CarLoanCalculationResult = React.useMemo(()=>{
        return getCarLoanCalculations(config, loanValue, termValue, loanType, carType)
    },[config, loanValue, termValue, loanType, carType])

    
    const onChangeLoan = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        if (value <= config.loan.max) {
            setLoanValue(value);
        }
    };

    const radioLoanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoanType(e.target.value as CarLoanTypes);
    };
    const radioCarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCarType(e.target.value as CarTypes);
    };

    const onApply = () => {
        setFormVisible(true);
        if (refForScroll.current?.offsetTop) {
            window.scrollTo({ top: refForScroll.current?.offsetTop - 150, behavior: "smooth" })
        }
    }
    
    // // for use in both display and for sending in form
    const summaryData:LoanSummaryData[] = createCarLoanSummaryData({
        calculatedResult: calculated
    });
    

    return (
        <div>
        <GridContainer>
        <CalculatorTitle title={CarLoanLabels.title} />
            <GridColumn>
                <BetriCard>
                    <div className="betri-car-loan-types">
                        <BetriRadio
                            name="loantype"
                            value={CarLoanTypes.GREEN}
                            checked={CarLoanTypes.GREEN === loanType}
                            onChange={radioLoanChange}
                            title={CarLoanTypes.GREEN}
                            tooltip={CarLoanLabels.greenTooltip}
                        />
                        <BetriRadio
                            name="loantype"
                            value={CarLoanTypes.REGULAR}
                            checked={CarLoanTypes.REGULAR === loanType}
                            onChange={radioLoanChange}
                            title={CarLoanTypes.REGULAR}
                            tooltip={CarLoanLabels.regularTooltip}
                        />
                    </div>
                    <div className="betri-car-loan-types">
                        <BetriRadio
                            name="cartype"
                            value={CarTypes.NEW}
                            checked={CarTypes.NEW === carType}
                            onChange={radioCarChange}
                            title={CarTypes.NEW}
                            tooltip={CarLoanLabels.newCarTooltip}
                        />
                        <BetriRadio
                            name="cartype"
                            value={CarTypes.USED}
                            checked={CarTypes.USED === carType}
                            onChange={radioCarChange}
                            title={CarTypes.USED}
                            tooltip={CarLoanLabels.usedCarTooltip}
                        />
                    </div>
                    <div>
                        <SlideAndInput
                            title={CarLoanLabels.loan}
                            loan={config.loan}
                            value={loanValue}
                            setValue={setLoanValue}
                            onChangeInput={onChangeLoan}
                            formattingFunction={v => <Currency amount={v} />}
                        />
                        <SlideAndInput
                            title={CarLoanLabels.term}
                            loan={config.term}
                            value={termValue}
                            setValue={setTermValue}
                            formattingFunction={v => <Year amount={v} />}
                        />
                    </div>
                </BetriCard>
            </GridColumn>
            <GridColumn>
                <BetriCard>
                    <LoanSummary topText={SharedLabels.resultTop} buttonText={CarLoanLabels.buttonText} summaryText={SharedLabels.summaryTopText} monthlyTotal={calculated.monthlyPayment} loanSummaryData={summaryData} onApplyClick={onApply} />
                </BetriCard>
            </GridColumn>
            <div ref={refForScroll} />
            {formVisible && (
                <GridColumn>
                    <BetriCard>
                        <BetriForm summaryData={summaryData} submitData={submitData} />
                    </BetriCard>
                </GridColumn>
            )}
            <GridColumn>
                <FooterText boldText={SharedLabels.cautionSpan} text={SharedLabels.caution} />
            </GridColumn>
        </GridContainer>
        </div>
    );
};

export default CarLoan;
