import { CarLoanConfig, CarLoanTypes, CarTypes } from "../calculators/car-loan/car-loan";
import { CarLoanLabels, SharedLabels } from "../constants/translations";
import { converToFoCurrencyString, getMonthlyPayment, LoanSummaryData } from "./loan-math";

export interface CarLoanCalculationResult {
    ownFinancing: number;
    bankFinancing: number;
    totalFeesWithoutCreationPrice: number;
    creationPrice: number;
    totalFees: number;
    registrationFeeVar: number;
    monthlyPayment: number;
    totalTax: number;
    loan: number;
    term: number;
    totalRent: number;
}
export const getCarLoanCalculations = (
    conf: CarLoanConfig,
    loanVal: number,
    termVal: number,
    loanType: CarLoanTypes,
    carType: CarTypes
) => {
    // rent depending on car type and loan type
    let rentToUse = 0.3;
    if (loanType === CarLoanTypes.GREEN) {
        rentToUse = carType === CarTypes.NEW ? conf.greenloan.rent2 : conf.greenloan.rent1;
    }
    if (loanType === CarLoanTypes.REGULAR) {
        rentToUse = carType === CarTypes.NEW ? conf.rent1 : conf.rent2;
    }

    // own finance depend on carType
    const ownFinancing = carType === CarTypes.NEW ? loanVal * conf.paymentPercent : loanVal * 0.3;

    let bankFinancing = loanVal - ownFinancing;

    const totalFeesWithoutCreationPrice =
        conf.handlingFee + conf.mortgageRegistration + conf.mortgageRegistration2;
    const creationPrice = (bankFinancing + totalFeesWithoutCreationPrice) * conf.creationPrice;

    let totalFees = totalFeesWithoutCreationPrice + creationPrice;
    bankFinancing += totalFees;

    const registrationFeeVar = 0.01 * bankFinancing;
    bankFinancing += registrationFeeVar;
    totalFees += registrationFeeVar;

    const t = Math.pow(1 + rentToUse / 4, 4 / 12) - 1;

    const monthlyPayment = getMonthlyPayment(bankFinancing, t, 12 * termVal);
    const totalTax = monthlyPayment * termVal * 12 - bankFinancing;

    const result: CarLoanCalculationResult = {
        ownFinancing,
        bankFinancing,
        totalFeesWithoutCreationPrice,
        creationPrice,
        totalFees,
        registrationFeeVar,
        monthlyPayment,
        totalTax,
        loan: loanVal,
        term: termVal,
        totalRent: rentToUse * 100
    };
    return result;
};

export interface CarLoanSummaryInput {
    calculatedResult: CarLoanCalculationResult;
}

export const createCarLoanSummaryData = (summaryInput: CarLoanSummaryInput): LoanSummaryData[] => {
    const { calculatedResult } = summaryInput;

    return [
        {
            title: "Billán",
            value: converToFoCurrencyString(calculatedResult.loan - calculatedResult.ownFinancing),
            info: SharedLabels.summaryInfo
        },
        {
            title: CarLoanLabels.ownFinancing,
            value: converToFoCurrencyString(calculatedResult.ownFinancing)
        },
        {
            title: CarLoanLabels.term,
            value: calculatedResult.term + SharedLabels.termYears
        },
        {
            title: CarLoanLabels.totalFees,
            value: converToFoCurrencyString(calculatedResult.totalFees),
            info: CarLoanLabels.carInfo
        },
        {
            // title: CarLoanLabels.totalTax + calculatedResult.totalRent + "%",
            title: CarLoanLabels.totalTax,
            value: converToFoCurrencyString(calculatedResult.totalTax)
        }
    ];
};
