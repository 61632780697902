import { useEffect, useState } from "react";

function useFormIntegration(textAreaCssSelector: string, submitButtonCssSelector: string) {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        try {
            const formModuleToHide = document.querySelectorAll(".form-module-a")[0] as HTMLDivElement;
            formModuleToHide.style.display = "none";
        } catch (e) {
            /* tslint:disable */
            console.log("Didn't find SC form on page to integrate with.");
            /* tslint:enable */
        }
    }, []);

    const submitData = (data: string) => {
        setSubmitting(true);
        const textMessage = document.querySelectorAll(textAreaCssSelector)[0] as HTMLTextAreaElement;
        const submitButton = document.querySelectorAll(submitButtonCssSelector)[0] as HTMLButtonElement;
        if (textMessage && submitButton) {
            textMessage.value = data;
            submitButton.click();
            setSubmitting(false);
        } else {
            setError(true);
        }
    };

    return {
        submitData,
        submitting,
        error
    };
}

export default useFormIntegration;
