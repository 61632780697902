import * as React from "react";

interface FormTermsCheckboxProps {
    id?: string;
    name?: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    termsText?: string;
    checked?: boolean;
}

const FormTermsCheckbox: React.FunctionComponent<FormTermsCheckboxProps> = props => {
    const { termsText, ...rest } = props;

    return (
        <div className="betri-form-terms-wrapper">
            <input type="checkbox" {...rest} /> {termsText}
        </div>
    );
};

export default FormTermsCheckbox;
