import { ConsumerLoanConfig } from "../calculators/consumer-loans/consumer-loan";
import { ConsumerLoanLabels, SharedLabels } from "../constants/translations";
import { converToFoCurrencyString, converToFoCurrencyStringNoNegative, getMonthlyPayment, LoanSummaryData, totalRentInterests } from "./loan-math";

export interface ConsumerLoanCalculationResult {
    loan: number;
    term: number;
    orkulan: number;
    interestValue: number;
    monthlyPayment: number;
    totalWithSupport: number;
}

export const getConsumerLoanCalculations = (conf: ConsumerLoanConfig, loanVal: number, termVal: number) => {

    const rentToUse: number = conf.rent;
    const orkulan = (loanVal / (termVal * 12)) + (loanVal * (rentToUse / (termVal * 12)))
    const monthlyPayment = getMonthlyPayment(loanVal, rentToUse /12, 12 * termVal)
    const interestValue = (totalRentInterests(monthlyPayment, rentToUse , 12, loanVal) * conf.rentSupport ) / 12;
    const totalWithSupport = monthlyPayment - interestValue;

    const result: ConsumerLoanCalculationResult = {
        orkulan,
        interestValue,
        monthlyPayment,
        totalWithSupport,
        loan: loanVal,
        term: termVal
    };
    return result;
};

export interface ConsumerLoanSummaryInput {
    calculatedResult: ConsumerLoanCalculationResult;
}

export const createConsumerLoanSummaryData = (summaryInput: ConsumerLoanSummaryInput): LoanSummaryData[] => {
    const { calculatedResult } = summaryInput;
    return [
        {
            title: ConsumerLoanLabels.orkulan,
            value: converToFoCurrencyString(calculatedResult.loan)
        },
        {
            title: ConsumerLoanLabels.term,
            value: calculatedResult.term + SharedLabels.termYears
        },
        {
            title: ConsumerLoanLabels.loanFee,
            value: converToFoCurrencyStringNoNegative(calculatedResult.monthlyPayment)
        },
        {
            title: ConsumerLoanLabels.interestValue,
            value: converToFoCurrencyStringNoNegative(calculatedResult.interestValue)
        }
    ];
};
