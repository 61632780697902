import * as React from "react";
import { converToFoYearString } from "../../math-utils/loan-math";

interface YearProps {
    amount: number;
}

const Year: React.FC<YearProps> = ({ amount }) => {
    amount = amount < 0 ? 0 : amount;
    return <span className="betri-year">{converToFoYearString(amount)}</span>;
};

export default Year;
