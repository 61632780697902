import * as React from "react";
import { converToFoCurrencyString } from "../../math-utils/loan-math";

interface CurrencyProps {
    amount: number;
    suffix?: string;
}

const Currency: React.FC<CurrencyProps> = ({ amount, suffix }) => {

    amount = amount < 0 ? 0 : amount;
    return <span className="betri-currency">{converToFoCurrencyString(amount, suffix)}</span>;
};

export default Currency;
