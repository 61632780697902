import { SharedLabels } from "../../constants/translations";
import { BetriFormData } from "./betri-form";

export const validateForm = (data: Partial<BetriFormData>, hasChooseCityInput?: boolean) => {
    // some times there's not an error so I dont need every data. That's when I use Partial, so typescript won't complain.
    const errors: Partial<BetriFormData> = {};

    const { email, name, address, phoneNumber, chooseCity } = data;

    if (!email) {
        errors.email = SharedLabels.formEmailMissing;
    } else {
        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        pattern.test(email) ? (errors.email = undefined) : (errors.email = SharedLabels.formEmailError);
    }

    if (!name) {
        errors.name = SharedLabels.formNameMissing;
    } else {
        const pattern = new RegExp("^\\D*$");
        pattern.test(name) ? (errors.name = undefined) : (errors.name = SharedLabels.formNameError);
    }

    if (!address) {
        errors.address = SharedLabels.formAddressMissing;
    } else {
        const pattern = new RegExp("[^]*");
        pattern.test(address)
            ? (errors.address = undefined)
            : (errors.address = SharedLabels.formAddressError);
    }

    if (!phoneNumber) {
        errors.phoneNumber = SharedLabels.formTelMissing;
    } else {
        const pattern = new RegExp("^[0-9]{6}$");
        pattern.test(phoneNumber)
            ? (errors.phoneNumber = undefined)
            : (errors.phoneNumber = SharedLabels.formTelError);
    }

    // If the input for choosing a city exists, and there's an empty string, then add the error.
    if (hasChooseCityInput && chooseCity === "") {
        errors.chooseCity = SharedLabels.formChooseCityMissing;
    }

    return errors;
};
