export const HouseLoanLabels = {
    title: "Rokna Bústaðarlán",
    summaryTitle: "Bústaðarlán",
    loan: "Keypsprísur",
    ownFinancing: "Eginfígging",
    interestValue: "Rentustuðul",
    loanFee: "Lánsgjald",
    term: "Afturgjaldstíð",
    toptext: "Niðanfyri kanst tú gera eina vegleiðandi orkulánsútrokning.",
    regularTooltip: "Til bústað við oljufýring",
    greenTooltip: "Til bústað við jarðhita, fjarhita\n ella hitapumpu",
    buttonText: "Søk um bústaðarlán"
};

export const ConsumerLoanLabels = {
    title: "Rokna Orkulán",
    loan: "Lánsupphædd",
    term: "Afturgjaldstíð",
    buttonText: "Søk um orkulán",
    orkulan: "Lánsupphædd",
    loanFee: "Lánsgjald",
    interestValue: "Rentustuðul"
};

export const CarLoanLabels = {
    title: "Rokna Billán",
    greenTooltip: "el bilur",
    regularTooltip: "bensin ella diesel bilur",
    newCarTooltip: "eginfígging min. 20%",
    usedCarTooltip: "eginfígging min. 30%",
    loan: "Keypsprísur",
    term: "Afturgjaldstíð",
    buttonText: "Søk um billán",
    ownFinancing: "Eginfígging",
    bankFinancingInitial: "Bankafígging",
    totalFees: "Stovningarkostnaður",
    bankFinancing: "Lánsupphædd",
    totalTax: "Samlað renta fyri alt tíðarskeiðið ",
    monthlyPayment: "Mánaðarligt gjald",
    carInfo:
        "Stovningarprovisión á 3% av billáninum\n Avgreiðslugjald\n Tinglýsing til Landskassan á 1% av pantinum\n Veðhavaralýsing til tryggingarstovn\n Tinglýsingaravgjald til Umhvørvisstovuna"
};

export const SharedLabels = {
    houseLabels: HouseLoanLabels,
    carLabels: CarLoanLabels,
    consumerLabels: ConsumerLoanLabels,
    resultTop: "Mánaðargjald",
    summaryTopText: "Mánaðargjaldið er grundað á: ",
    summaryInfo: "(keypsprísur ÷ eginfígging)",
    cautionSpan: "Fyrivarni: ",
    caution:
        "Útrokningin er vegleiðandi. Tú ert vælkomin at seta teg í samband við Betri Banka fyri at fáa eina neyva útrokning, sum tekur støði í tínum fíggjarviðurskiftum.",
    terms:
        "Eg samtykki at Betri Banki P/F viðgerð mínar persónsupplýsingar í hesum sambandi. Persónupplýsingarnar verða skrásettar og goymdar eftir vanligu leiðreglum bankans. Eg eri kunnað/ur um viðgerð av persónupplýsingum í Betri Banka P/F, sum eisini finst á heimasíðu bankans.",
    sending: "Sending",
    send: "Send",
    termYears: " ár",
    formName: "Navn *",
    formNameMissing: "Navn manglar",
    formNameError: "Navnið er ikki í gildi",
    formAddress: "Bústaður *",
    formAddressMissing: "Bústaður manglar",
    // address error empty for now, can maybe be changed later.
    formAddressError: "",
    formEmail: "Teldupostur *",
    formEmailMissing: "Teldupostur manglar",
    formEmailError: "Telduposturin er ikki í gildi",
    formTel: "Telefonnummar *",
    formTelMissing: "Telefonnummar manglar",
    formTelError: "Telefonnummarið er ikki í gildi",
    formChooseCity: "Hvar í Føroyum umhugsar tú at keypa ella byggja bústað? *",
    formChooseCityMissing: "Hetta økið manglar"
};
