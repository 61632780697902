import * as React from "react";

interface Props {
    className?: string;
    title: string;
    tooltip?: string;
    name: string;
    value: string;
    checked?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const BetriRadio: React.FunctionComponent<Props> = props => {
    const { title, className, tooltip, ...rest } = props;
    return (
        <div className={`betri-input-radio ${className}`}>
            <label className="betri-input-label">
                <input type="radio" {...rest} />
                {title}
                <small>{tooltip}</small>
            </label>
        </div>
    );
};

export default BetriRadio;
