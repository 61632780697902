import { SharedLabels } from "../constants/translations";

export const getMonthlyPayment = (t: number, e: number, n: number) => {
    return (t * e) / (1 - Math.pow(1 + e, -n));
};

/**
 * @param totalLoanValue the total loan value
 * @param loanValueMinusOwn the loan value minus own finance
 * @returns an object with a p1 and p2 value for prioririe one and two
 */
export const priority = (totalLoanValue: number, loanValueMinusOwn: number): { p1: number; p2: number } => {
    loanValueMinusOwn = Math.min(totalLoanValue, 0.8 * loanValueMinusOwn);
    return {
        p1: loanValueMinusOwn,
        p2: totalLoanValue - loanValueMinusOwn
    };
};

/**
 *
 * @param monthlyPayment monthly payment
 * @param rentPercentage rent percentage
 * @param monthsInAYear payments (ie. 12 in a year)
 * @param loanAmount loan amount
 * @returns result is the total interests payed
 */
export const totalRentInterests = (
    monthlyPayment: number,
    rentPercentage: number,
    monthsInAYear: number,
    loanAmount: number
) => {
    let result = 0;
    for (let a = 0, o = 1; o <= monthsInAYear; o++) {
        const interestPerMonth = (loanAmount * rentPercentage) / monthsInAYear;
        loanAmount -= monthlyPayment - interestPerMonth; // deduct from the loan
        a += interestPerMonth; // add the interestert payed
        result = a;
    }
    return result;
};

export const termTooltip = (t: number): string => {
    return Math.floor(t) + (t % 1 !== 0 ? "½" : "") + SharedLabels.termYears;
};

export const converToFoCurrencyString = (t: number, e?: string): string => {
    const f = Math.round(parseFloat(t.toString()))
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1.");
    return (e = undefined !== e ? e : " kr."), f.substring(0, f.length - 3) + e;
};

export const converToFoCurrencyStringNoNegative = (amount: number, e?: string): string => {
    amount = amount < 0 ? 0 : amount;
    return converToFoCurrencyString(amount, e)
};

export const converToFoYearString = (t: number, e?: string): string => {
    const f = Math.round(parseFloat(t.toString()))
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1.");
    return (e = undefined !== e ? e : SharedLabels.termYears), f.substring(0, f.length - 3) + e;
};
// export const addRent = function (t, e, n) {
//     for (var i = 0; i < e; i++) n += n * t;
//     return n;
// };

// export const savings = function (t, e, n, i, a) {
//     null == a && (a = 0.35);
//     (a = Math.pow(1 + (i * (1 - a)) / 2, 1 / 6) - 1),
//         (e = (e * (Math.pow(1 + a, 12 * n) - 1)) / a),
//         (e += e * a),
//         (n = t * Math.pow(1 + a, 12 * n));
//     return Math.round(e + n);
// };

// export const akp = function (t, e) {
//     t = (function (t) {
//         (min = 0), (max = 1);
//         do {
//             guest = (min + max) / 2;
//             for (var e = (NPV = 0); e < t.length; e++) NPV += t[e] / Math.pow(1 + guest, e);
//         } while ((0 < NPV ? (min = guest) : (max = guest), guest < 1 && 1e-6 < Math.abs(NPV)));
//         return 1 == guest ? void 0 : guest;
//     })(t);
//     return Math.pow(t + 1, e) - 1;
// };

export interface LoanSummaryData {
    title: string;
    value: string;
    info?: string;
}
