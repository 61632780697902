import * as React from "react";
import { SUBMIT_BUTTON_CSS_CLASS, TEXT_AREA_CSS_CLASS } from "../../constants/default-config";
import { HouseLoanLabels, SharedLabels } from "../../constants/translations";
import useFormIntegration from "../../hooks/use-form-integration";
import { urlLoanValue, urlTermValue } from "../../libs/url-params";
import { createHouseLoanSummaryData, getHouseLoanCalculationsFromConfig, HouseLoanCalculationsResult } from "../../math-utils/loan-math-house";
import BetriCard from "../../ui/card/betri-card";
import Currency from "../../ui/currency/currency";
import FooterText from "../../ui/footer-text/footer-text";
import BetriForm from "../../ui/form/betri-form";
import GridColumn from "../../ui/grid/grid-column";
import GridContainer from "../../ui/grid/grid-container";
import BetriRadio from "../../ui/radio/radio";
import SlideAndInput from "../../ui/slide-and-input/slide-and-input";
import { SliderConfig } from "../../ui/slider/slider";
import CalculatorTitle from "../../ui/title/calculator-title";
import Year from "../../ui/year/year";
import LoanSummary from "../shared/loan-summary/loan-summary";
import { LoanTypes, SharedLoanProps } from "../shared/shared-configs";


export interface HouseLoanConfig {
    loan: SliderConfig;
    ownFinancing: SliderConfig;
    term: SliderConfig;
    text: {
        loan: string;
        ownFinancing: string;
        term: string;
        caution: string;
    };
    rent1: number;
    rent2: number;
    rentSupport: number;
    maxRentSupport: number;
    greenloan: { rent1: number };
    loanType: LoanTypes;
}

export interface HouseLoanProps extends SharedLoanProps{
    config: HouseLoanConfig;
}



const HouseLoan: React.FC<HouseLoanProps> = ({ config }) => {
    const { submitData } = useFormIntegration(TEXT_AREA_CSS_CLASS, SUBMIT_BUTTON_CSS_CLASS);
    const refForScroll = React.useRef<HTMLDivElement>(null);
    const [formVisible, setFormVisible] = React.useState<boolean>(false);
    
    const [loanValue, setLoanValue] = React.useState<number>(urlLoanValue ||  config.loan.value);
    const [ownFinanceValue, setOwnFinanceValue] = React.useState<number>(config.ownFinancing.value);
    const [termValue, setTermValue] = React.useState<number>(urlTermValue ||config.term.value);
    const [loanType, setLoanType] = React.useState<LoanTypes>(LoanTypes.GREEN);

    

    const calculated: HouseLoanCalculationsResult = React.useMemo(
        () => {
            return getHouseLoanCalculationsFromConfig(
                config,
                loanValue,
                termValue,
                ownFinanceValue,
                loanType
            );
        },
        [loanValue, ownFinanceValue, termValue, loanType]
    );

    const radioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoanType(e.target.value as LoanTypes);
    };

    const onChangeFinance = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = +e.target.value;
        if (value <= config.ownFinancing.max) {
            setOwnFinanceValue(Number(e.target.value));
        }
    };

    const onChangeLoan = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = +e.target.value;
        if (value <= config.loan.max) {
            setLoanValue(value);
        }
    };
    
    const onApply = () => {
        setFormVisible(true);
        if (refForScroll.current?.offsetTop) {
            window.scrollTo({ top: refForScroll.current?.offsetTop - 150, behavior: "smooth" })
        }
    }

    // for use in both display and for sending in form
    const summaryData = createHouseLoanSummaryData({
        loanValue,
        termValue,
        config,
        calculated,
        ownFinanceValue
    });

    return (
        <GridContainer>
        <CalculatorTitle title={HouseLoanLabels.title} />
            <GridColumn>
                <BetriCard>
                    <div className="betri-house-loan-types">       
                        <BetriRadio
                            name="loantype"
                            value={LoanTypes.GREEN}
                            checked={LoanTypes.GREEN === loanType}
                            onChange={radioChange}
                            title={LoanTypes.GREEN}
                            tooltip={HouseLoanLabels.greenTooltip}
                        />
                           <BetriRadio
                            name="loantype"
                            value={LoanTypes.REGULAR}
                            checked={LoanTypes.REGULAR === loanType}
                            onChange={radioChange}
                            title={LoanTypes.REGULAR}
                            tooltip={HouseLoanLabels.regularTooltip}
                        />
                    </div>
                    <div className="betri-slider-box">
                        <SlideAndInput
                            title={HouseLoanLabels.loan}
                            loan={config.loan}
                            value={loanValue}
                            setValue={setLoanValue}
                            onChangeInput={onChangeLoan}
                            formattingFunction={v => <Currency amount={v} />}
                        />
                        <SlideAndInput
                            title={HouseLoanLabels.ownFinancing}
                            loan={config.ownFinancing}
                            value={ownFinanceValue}
                            setValue={setOwnFinanceValue}
                            onChangeInput={onChangeFinance}
                            formattingFunction={v => <Currency amount={v} />}
                            hideRight
                            
                        />
                        <SlideAndInput
                            title={HouseLoanLabels.term}
                            loan={config.term}
                            value={termValue}
                            setValue={setTermValue}
                            formattingFunction={v => <Year amount={v} />}
                        />
                    </div>
                </BetriCard>
            </GridColumn>
            <GridColumn>
                <BetriCard>
                    <LoanSummary
                        monthlyTotal={calculated.totalWithSupport}
                        loanSummaryData={summaryData}
                        onApplyClick={onApply}
                        topText={SharedLabels.resultTop}
                        buttonText={HouseLoanLabels.buttonText}
                        summaryText={SharedLabels.summaryTopText}
                    />
                </BetriCard>
            </GridColumn>
            <div ref={refForScroll} />
            {formVisible && (
                <GridColumn>
                    <BetriCard>
                        <BetriForm summaryData={summaryData} submitData={submitData} hasChooseCityInput />
                    </BetriCard>
                </GridColumn>
            )}
            <GridColumn>
                <FooterText boldText={SharedLabels.cautionSpan} text={SharedLabels.caution} />
            </GridColumn>
        </GridContainer>
    );
};

export default HouseLoan;