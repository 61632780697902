import * as React from "react";

interface Props {
    className?: string;
    left?: string | React.ReactNode;
    right?: string | React.ReactNode;
    tooltip?: string | React.ReactNode;
}

// modified it, added a type, changed children to props in order to add a classname.
const SummaryRow: React.FC<Props> = props => {
    return (
        <div className={`betri-summary-row ${props.className}`}>
            <div className="betri-summary-row-left">{props.left}</div>
            <div className="betri-summary-row-right">{props.right}</div>
            {props.tooltip && <div className="betri-summary-row-tooltip">{props.tooltip}</div>}
        </div>
    );
};

export default SummaryRow;
