import * as React from "react";

interface Props {
    text: string;
    boldText: string;
}

const FooterText: React.FC<Props> = ({ text, boldText }) => {
    return (
        <div className="betri-footer-text">
            <small className="betri-footer-text-small">
                <span>{boldText}</span>
                {text}
            </small>
        </div>
    );
};

export default FooterText;
