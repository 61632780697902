import * as React from "react";

export interface CalculatorTitleProps {
    title: string;
}

const CalculatorTitle: React.FunctionComponent<CalculatorTitleProps> = ({title}) => {
    return <div className="betri-calc-title">{title}</div>;
};

export default CalculatorTitle;