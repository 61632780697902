function getUrlParams() {
    const params = new URL((window as any).location).searchParams;
    return params;
}

export function getUrlParamByName(name: string) {
    const params = getUrlParams();
    return params.get(name);
}

// ?loanValue=1580054&termValue=10
export const urlLoanValue = Number(getUrlParamByName("loanValue"));
export const urlTermValue = Number(getUrlParamByName("termValue"));
