import { HouseLoanConfig, HouseLoanProps } from "../calculators/house-loan/house-loan";
import { LoanTypes } from "../calculators/shared/shared-configs";
import { HouseLoanLabels, SharedLabels } from "../constants/translations";
import {
    converToFoCurrencyString,
    converToFoCurrencyStringNoNegative,
    getMonthlyPayment,
    LoanSummaryData,
    priority,
    totalRentInterests
} from "./loan-math";

export interface HouseLoanCalculationsResult {
    rent1: number;
    bankFinancing: number;
    monthlyPayment1: number;
    monthlyPayment2: number;
    rentSupport: number;
    total: number;
    totalWithSupport: number;
}

// loan: loanvalue * 

export const getHouseLoanCalculationsFromConfig = (
    config: HouseLoanProps["config"],
    loanValue: number,
    termValue: number,
    ownFinanceValue: number,
    loanType: LoanTypes
): HouseLoanCalculationsResult => {
    const fullLoan: number = loanValue;
    const bankFinancedLoan = fullLoan - ownFinanceValue;
    const terms = termValue;
    // Check if there is need for two loans (p1 and p2) in case the onFinance is less than 20%
    const prios = priority(bankFinancedLoan, fullLoan);

    // result values
    const rent1 = (LoanTypes.GREEN === loanType ? config.greenloan : config).rent1;
    const bankFinancing = bankFinancedLoan;
    const monthlyPayment1 = getMonthlyPayment(prios.p1, rent1 / 12, 12 * terms);
    const monthlyPayment2 = getMonthlyPayment(prios.p2, config.rent2 / 12, 12 * terms);

    // make sure not to exceed max rent support per month
    const prioOneTotalInterest =
        (totalRentInterests(monthlyPayment1, rent1, 12, prios.p1) * config.rentSupport) / 12;
    const prioTwoTotalInterest =
        (totalRentInterests(monthlyPayment2, config.rent2, 12, prios.p2) * config.rentSupport) / 12;
    let rentSupport = prioOneTotalInterest + prioTwoTotalInterest;
    if (rentSupport > config.maxRentSupport) {
        rentSupport = config.maxRentSupport;
    }

    const total = monthlyPayment1 + monthlyPayment2;
    const totalWithSupport = total - rentSupport;
    
    const result: HouseLoanCalculationsResult = {
        rent1,
        bankFinancing,
        monthlyPayment1,
        monthlyPayment2,
        rentSupport,
        total,
        totalWithSupport
    };
    return result;
};

export interface HouseLoanSummaryInput {
    loanValue: number;
    ownFinanceValue: number;
    termValue: number;
    calculated: HouseLoanCalculationsResult;
    config: HouseLoanConfig;
}

export const createHouseLoanSummaryData = (
    houseLoanSummaryInput: HouseLoanSummaryInput
): LoanSummaryData[] => {
    const { loanValue, ownFinanceValue, termValue, calculated } = houseLoanSummaryInput;
    return [
        {
            title: HouseLoanLabels.summaryTitle,
            value: converToFoCurrencyStringNoNegative(loanValue - ownFinanceValue),
            info: SharedLabels.summaryInfo
        },
        {
            title: HouseLoanLabels.ownFinancing,
            value: converToFoCurrencyString(ownFinanceValue)
        },
        {
            title: HouseLoanLabels.term,
            value: termValue + SharedLabels.termYears
        },
        {
            // check it later, not sure if it's the right calculation
            title: HouseLoanLabels.loanFee,
            value: converToFoCurrencyStringNoNegative(calculated.total)
        },
        {
            title: HouseLoanLabels.interestValue,
            value: converToFoCurrencyStringNoNegative(calculated.rentSupport)
        }
    ];
};

export const formatLoanSummaryDataForMailString = (data: LoanSummaryData[]): string => {
    let text = "\n";
    text = text + "---------------------------\n";
    data.forEach(row => {
        text = text + row.title + ": " + row.value + "\n";
        if (row.info) {
            text = text + row.info + "\n";
        }
    });
    text = text + "---------------------------\n";
    return text;
};

