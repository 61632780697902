import * as React from "react";
import BetriSlider, { SliderConfig } from "../slider/slider";

export interface SlideAndInputProps {
    hideRight?: boolean;
    title: string;
    loan: SliderConfig;
    setValue: (value: number) => void;
    onChangeInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: number;
    formattingFunction?: SliderConfig["formattingFunction"];
}

const SlideAndInput: React.FC<SlideAndInputProps> = ({
    hideRight,
    loan,
    title,
    setValue,
    value,
    formattingFunction
}) => {
    return (
        <div className="betri-slider-container">
            <div className="betri-slider-container-title">{title}</div>
            <div className="betri-slider-container-slider">
                <BetriSlider
                    {...loan}
                    onChange={setValue}
                    value={value}
                    formattingFunction={formattingFunction}
                    hideRight={hideRight}
                />
            </div>
            {/* <div className="betri-slider-container-input">
                <input type="text" value={value} onChange={onChangeInput} />
            </div> */}
        </div>
    );
};

export default SlideAndInput;
