import * as React from "react";

interface BetriLabelInputProps {
    label?: string;
    type?: string;
    id: string;
    name: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    errorMessage?: string;
}

const BetriLabelInput: React.FunctionComponent<BetriLabelInputProps> = props => {
    const { errorMessage, label, id, ...rest } = props;
    const errorClass = errorMessage ? "betri-label-and-input--error" : "";
    return (
        <div className={`betri-label-and-input ${errorClass}`}>
            <label htmlFor={id}>{label}</label>
            <input {...rest} id={id} required />
            {errorMessage && <span className="betri-label-and-input-errorMsg">{errorMessage}</span>}
        </div>
    );
};

export default BetriLabelInput;
